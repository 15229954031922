export const environment = {
	COMPANY_NAME: "evollo",
	APP_NAME: "ID",
	production: true,
	apiUrl: "https://bradesco-account.api.evollo.cloud",
	qualityApiUrl: "https://bradesco-quality.api.evollo.cloud",
	recaptchaId: "6Le_4tQmAAAAALwMgkZF8dyNpcwY9KG1-a8_se6m",
	webClientId: 'ebtuo1nds71q8i9porc3ttsbr',
	apps: {
		id: "https://bradesco.id.evollo.com.br",
		admin: "https://bradesco.admin.evollo.com.br",
		quality: "https://bradesco.quality.evollo.com.br",
		cognito: 'bradesco-id.auth.us-east-1.amazoncognito.com'
	},
	features: {
		enableSignUp: false,
		enableAzureSignUp: false,
		recaptchaEnabled: true,
		enableSupport: false,
		enableFaq: false
	}
};
